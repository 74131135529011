export enum LoggerMessageTypeEnum {
    DevInfo = 1, // develop info
    DevWarn, // develop warning
    DevError, // develop error
    DevCriticalError, // develop critical error (stop app)
    InternalInfo, // internal info with logging
    InternalWarn, // internal warning with logging
    InternalError, // internal error with logging
    InternalCriticalError, // internal critical with logging
    Trace, // trace for some systems (common)
    AnalyticTrace, // trace for analytic systems
}
